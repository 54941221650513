var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "main-dashborad",
      staticClass: "work-permit-display-body",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.fullscreenChange.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "div",
              { staticClass: "card cardcontents workPermitDisplaycardlayer" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-header non-label-icon mapheader workPermitDisplaycardheader topworkPermitDisplaylayer",
                  },
                  [
                    _vm._m(0),
                    _c(
                      "q-btn",
                      {
                        staticClass: "workPermitDisplaymapstart fullscreen-btn",
                        attrs: {
                          flat: "",
                          round: "",
                          color: "white",
                          icon: _vm.fullscreen
                            ? "fullscreen_exit"
                            : "fullscreen",
                        },
                        on: { click: _vm.clickFullScreen },
                      },
                      [
                        _vm.fullscreen
                          ? _c("q-tooltip", [
                              _vm._v(
                                " " + _vm._s(_vm.$label("LBL0000327")) + " "
                              ),
                            ])
                          : _c("q-tooltip", [
                              _vm._v(
                                " " + _vm._s(_vm.$label("LBL0000328")) + " "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-more wokr-permit-display-header-input",
                      },
                      [
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 1,
                              expression: "mapObj.activeTask===1",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "workPermitPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.workPermitPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "workPermitPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.workPermitPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-btn",
                      {
                        staticClass: "workPermitDisplaymapstart startstopbtn",
                        attrs: {
                          outline: !_vm.mapObj.interval.isPause,
                          color: _vm.mapObj.interval.isPause
                            ? "green-6"
                            : "white",
                          "text-color": _vm.mapObj.interval.isPause
                            ? "white"
                            : "white",
                          size: "11px",
                          label: _vm.mapObj.interval.isPause
                            ? _vm.$comm.getLangLabel("LBL0000338")
                            : _vm.$comm.getLangLabel("LBL0000339"),
                          icon: _vm.mapObj.interval.isPause
                            ? "restart_alt"
                            : "pause",
                        },
                        on: { click: _vm.pause },
                      },
                      [_c("q-tooltip", [_vm._v("작업허가 위치 Rotate")])],
                      1
                    ),
                    _vm.mapObj.interval.isPause
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "card-more main-header-input mainmapstartSecond",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                label: "",
                                prefix: "반복 시간",
                                suffix: "(초)",
                                type: "number",
                                name: "settingSeconds",
                              },
                              model: {
                                value: _vm.settingSeconds,
                                callback: function ($$v) {
                                  _vm.settingSeconds = $$v
                                },
                                expression: "settingSeconds",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "card-body workPermitDisplaycardbody",
                    staticStyle: { padding: "15px !important" },
                    attrs: { loading: _vm.mapObj.loading },
                  },
                  [
                    !_vm.mapObj.loading
                      ? [
                          _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                            ? _c(
                                "q-carousel",
                                {
                                  staticClass: "workPermit-carousel",
                                  attrs: {
                                    swipeable: "",
                                    animated: "",
                                    arrows: "",
                                    infinite: "",
                                    "control-type": "regular",
                                    "control-color": "grey-6",
                                  },
                                  on: { input: _vm.changeCarousel },
                                  model: {
                                    value: _vm.mapObj.slide,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.mapObj, "slide", $$v)
                                    },
                                    expression: "mapObj.slide",
                                  },
                                },
                                _vm._l(_vm.mapObj.maps, function (map, idx) {
                                  return _c(
                                    "q-carousel-slide",
                                    {
                                      key: idx,
                                      attrs: {
                                        name: idx,
                                        "img-src": map.mapSrc,
                                      },
                                    },
                                    [
                                      [
                                        _c("div", {
                                          staticClass: "mapTitleDiv",
                                          domProps: {
                                            textContent: _vm._s(map.mapName),
                                          },
                                        }),
                                        _vm.mapObj.activeTask === 1
                                          ? _vm._l(
                                              map.maps,
                                              function (item, idx) {
                                                return _c(
                                                  "VueDraggableResizable",
                                                  {
                                                    key: idx,
                                                    ref: "markImage",
                                                    refInFor: true,
                                                    staticClass:
                                                      "workPermitDisplayMarkImage",
                                                    class:
                                                      item.idx ===
                                                      _vm.mapObj.maps[0]
                                                        .haveWorkPermitIdxs[
                                                        _vm.mapObj
                                                          .activeMarked - 1
                                                      ]
                                                        ? "selected"
                                                        : "",
                                                    attrs: {
                                                      resizable: false,
                                                      parent: true,
                                                      draggable: false,
                                                      x:
                                                        item.locationXcoordinate *
                                                        _vm.mapRate *
                                                        _vm.mapObj.ratio *
                                                        1.395,
                                                      y:
                                                        item.locationYcoordinate *
                                                        _vm.mapRate *
                                                        _vm.mapObj.ratio *
                                                        1.395,
                                                      w: 40,
                                                      h: 40,
                                                      grid: [20, 20],
                                                    },
                                                  },
                                                  [
                                                    _c("c-map-marker", {
                                                      attrs: {
                                                        isSelected:
                                                          item.idx ===
                                                          _vm.mapObj.maps[0]
                                                            .haveWorkPermitIdxs[
                                                            _vm.mapObj
                                                              .activeMarked - 1
                                                          ],
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "pinInner",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "bg-white",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary workPermitTypeIcon",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "32px",
                                                                          },
                                                                        attrs: {
                                                                          name: "font_download",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-primary workPermitTypeIcon",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "32px",
                                                                          },
                                                                        attrs: {
                                                                          name: "font_download",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._l(map.areas, function (area, idx) {
                                          return _c(
                                            "VueDraggableResizable",
                                            {
                                              key: "area" + idx,
                                              staticClass:
                                                "work-permit-display-area-class",
                                              staticStyle: { "z-index": "1" },
                                              attrs: {
                                                parent: true,
                                                draggable: false,
                                                resizable: false,
                                                x:
                                                  area.x *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                y:
                                                  area.y *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                w:
                                                  area.w *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                h:
                                                  area.h *
                                                  _vm.mapRate *
                                                  _vm.mapObj.ratio,
                                                grid: [20, 20],
                                              },
                                            },
                                            [
                                              _c(
                                                "q-menu",
                                                {
                                                  ref: "areaproxy" + area.idx,
                                                  refInFor: true,
                                                  attrs: {
                                                    persistent: true,
                                                    "content-class":
                                                      "mainMapMenuLayer",
                                                    anchor: "top right",
                                                    self: "top left",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectedWorkPermit
                                                    .workPermits,
                                                  function (workPermit, idx) {
                                                    return _c(
                                                      "q-card",
                                                      {
                                                        key: idx,
                                                        class: [
                                                          "work-permit-display-card",
                                                          _vm.setStepClass(
                                                            workPermit.swpStepCd
                                                          ),
                                                        ],
                                                        attrs: {
                                                          flat: "",
                                                          bordered: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "q-item",
                                                          {
                                                            staticClass:
                                                              "work-permit-display-card-section work-permit-display-card-item",
                                                          },
                                                          [
                                                            _c(
                                                              "q-item-section",
                                                              {
                                                                attrs: {
                                                                  avatar: "",
                                                                },
                                                              },
                                                              [
                                                                _c("q-badge", {
                                                                  attrs: {
                                                                    rounded: "",
                                                                    color:
                                                                      _vm.setStepColor(
                                                                        workPermit.swpStepCd
                                                                      ),
                                                                    label:
                                                                      workPermit.swpStepName,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "q-item-section",
                                                              [
                                                                _c(
                                                                  "q-item-label",
                                                                  _vm._l(
                                                                    _vm.setTypeIcons(
                                                                      workPermit.sopWorkTypeCds
                                                                    ),
                                                                    function (
                                                                      icon,
                                                                      iconIdx
                                                                    ) {
                                                                      return _c(
                                                                        "q-icon",
                                                                        {
                                                                          key: iconIdx,
                                                                          staticClass:
                                                                            "text-red",
                                                                          attrs:
                                                                            {
                                                                              name: icon,
                                                                              size: "25px",
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "q-card-section",
                                                          {
                                                            staticClass:
                                                              "work-permit-display-card-section",
                                                          },
                                                          [
                                                            _c("q-icon", {
                                                              staticClass:
                                                                "text-indigo",
                                                              attrs: {
                                                                name: "chevron_right",
                                                              },
                                                            }),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  workPermit.workSummary
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " (" +
                                                                _vm._s(
                                                                  workPermit.workTime
                                                                ) +
                                                                ") "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        workPermit.vendorName
                                                          ? _c(
                                                              "q-card-section",
                                                              {
                                                                staticClass:
                                                                  "work-permit-display-card-section",
                                                              },
                                                              [
                                                                _c("q-icon", {
                                                                  staticClass:
                                                                    "text-indigo",
                                                                  attrs: {
                                                                    name: "chevron_right",
                                                                  },
                                                                }),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "작업업체 : " +
                                                                      _vm._s(
                                                                        workPermit.vendorName
                                                                      )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        workPermit.processName ||
                                                        workPermit.equipmentName
                                                          ? _c(
                                                              "q-card-section",
                                                              {
                                                                staticClass:
                                                                  "work-permit-display-card-section",
                                                              },
                                                              [
                                                                workPermit.processName
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "main-work-permit-card-proc",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-chip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                outline:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "blue",
                                                                                "text-color":
                                                                                  "white",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  workPermit.processName
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                workPermit.equipmentName
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "main-work-permit-card-equip",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-chip",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                outline:
                                                                                  "",
                                                                                square:
                                                                                  "",
                                                                                color:
                                                                                  "green",
                                                                                "text-color":
                                                                                  "white",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  workPermit.equipmentName
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                      ],
                                    ],
                                    2
                                  )
                                }),
                                1
                              )
                            : _c("el-empty", {
                                attrs: {
                                  "image-size": 435,
                                  description:
                                    _vm.$comm.getLangMessage("MSG0000122"),
                                },
                              }),
                        ]
                      : void 0,
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-inner-loading",
        {
          attrs: {
            showing: _vm.mapObj.loading,
            label: "화면 조정중입니다.",
            "label-class": "text-blue-grey",
            "label-style": "font-size: 8em",
            size: "12em",
          },
        },
        [
          _c("q-spinner-grid", { attrs: { size: "12em", color: "blue-grey" } }),
          _c(
            "div",
            {
              staticClass: "text-blue-grey",
              staticStyle: { "font-size": "3em" },
            },
            [_vm._v(" 화면 조정중 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", { staticClass: "title1" }, [_vm._v("안전작업허가서 현황")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }